<template lang="html">
  <div class="CommunityTagInput">
    <vue3-tags-input :tags="tags"
                   :placeholder="placeholder"
                   :add-tag-on-keys="[13, 188]"
                   @on-tags-changed="handleChangeTag"/>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'
import Vue3TagsInput from 'vue3-tags-input';

export default {
  name: 'CommunityTagInput',
  components:{
    Vue3TagsInput
  },
  props: {
    modelValue:{
      type: Array,
      default:() => []
    },
    placeholder:String
  },

  data() {
    return {
      tags: []
    }
  },
  methods: {
    handleChangeTag(tags) {
      this.tags = tags;
      this.$emit('update:modelValue',this.tags)
    },
    assignTags(){
      this.tags = this.modelValue
    }
  },
  mounted(){
    this.assignTags()
  }
}
</script>
<style lang="scss">
.CommunityTagInput{
  .v3ti{
  background: #f4f4f4;
    border: 1px solid #e4e4e4;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
    width: 100%;
    font-family: Roboto condensed;
    letter-spacing: 0.01em;
    outline: none;
}
.v3ti-new-tag{
    background      : #f4f4f4;
    font-size       : 1.3rem;
    width           : 100%;
    font-family: Roboto condensed;
    letter-spacing: 0.01em;
    outline: none;
    &::placeholder{
      opacity: 0.6;
    }
}
.v3ti-tag{
  background: var(--primary-color);
}
}


</style>
